import axios from 'axios';

// Define the base URL for your API (can be your endpoint IP or domain)
const apiURL = process.env.REACT_APP_BACKEND_API_URL;
const API = axios.create({
    baseURL: apiURL,
    timeout: 50000,
    headers: {
        'Content-Type': 'application/json',
    }
});

// You can set up interceptors if needed (e.g., for authorization tokens)
API.interceptors.request.use(
    (config) => {
        // Perform actions before request is sent, like adding auth tokens
        // Example: config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Interceptor for handling responses or errors
API.interceptors.response.use(
    (response) => response,
    (error) => {
        // You can handle global errors here
        return Promise.reject(error);
    }
);

export default API;
