import React, { useState, useEffect } from 'react';
import {useParams} from 'react-router-dom'; // To get the ID from the URL
import API from '../API';
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Box,
    Paper,
} from '@mui/material';
import {styled} from '@mui/material/styles';

// Styled components for consistent theme
const StyledTableCell = styled(TableCell)(({theme}) => ({
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

const DetailPage = () => {
    const {id} = useParams(); // Get the row ID from the URL
    const [data, setData] = useState(null);

    useEffect(() => {
        API.get(`/api/data/${id}`) // Fetch the data for this specific row
            .then((response) => {
                setData(response.data);
                // console.log(response.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [id]);

    const renderValue = (value) => {
        if (value === null || value === undefined) {
            return ''; // Return empty string for null/undefined values
        }

        // Render the value based on its type
        if (Array.isArray(value)) {
            return (
                <Table size="small">
                    <TableBody>
                        {value.map((item, index) => (
                            <TableRow key={index}>
                                <StyledTableCell>
                                    {typeof item === 'object' && item !== null ? (
                                        Object.entries(item).map(([subKey, subValue]) => (
                                            <div key={subKey}>
                                                <strong>{subKey}:</strong> {subValue}
                                            </div>
                                        ))
                                    ) : (
                                        item
                                    )}
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            );
        } else if (typeof value === 'object' && value !== null) {
            return <div>{renderRows(value)}</div>; // Recursively render objects
        }
        return value.toString(); // Render simple values
    };

    const renderRows = (data, level = 0) => {
        if (data === null || data === undefined) {
            return null; // Skip rendering if data is null/undefined
        }

        return Object.entries(data).map(([key, value]) => {
            // Skip numeric keys
            if (!isNaN(key)) {
                return null; // Skip rendering for numeric keys
            }

            return (
                <TableRow key={key}>
                    <StyledTableCell style={{paddingLeft: `${level * 20}px`}}>
                        {key}
                    </StyledTableCell>
                    <StyledTableCell>
                        {renderValue(value)}
                    </StyledTableCell>
                </TableRow>
            );
        });
    };


    if (!data) {
        return (
            <Paper elevation={3} style={{padding: '16px', margin: '20px'}}>
                <Typography variant="h6">Loading...</Typography>
            </Paper>
        );
    }

    // Parse the JSON string from the data field
    const parsedData = JSON.parse(data.data);
    console.warn(parsedData);
    return (
        <Paper elevation={3} style={{padding: '16px', margin: '20px'}}>
            <Box p={2}>
                <Typography variant="h4" gutterBottom>
                    Agent: {data.data_name}
                </Typography>

                {data.data_type === 'json' ? (
                    renderRows(parsedData[0]) // Access the first element of the array
                ) : (
                    <Typography variant="body1">{data.data}</Typography> // Render as plain text
                )}
            </Box>
        </Paper>
    );
};

export default DetailPage;
