import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DataTable from './components/DataTable';
import DetailPage from './components/DetailPage';

const App = () => {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<DataTable />} />
          <Route path="/details/:id" element={<DetailPage />} />
        </Routes>
      </Router>
  );
};

export default App;
