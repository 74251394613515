import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import API from '../API';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { IconButton } from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';

const DataTable = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0); // Zero-based page index
  const [rowsPerPage, setRowsPerPage] = useState(5); // Default rows per page
  const [totalRows, setTotalRows] = useState(0); // Total row count for pagination
  const navigate = useNavigate();
  const globalTheme = useTheme();

  useEffect(() => {
    const offset = page * rowsPerPage;

    console.log('Fetching data with offset:', offset, 'and limit:', rowsPerPage);

    // Fetch data with offset and limit
    API.get(`/api/data?offset=${offset}&limit=${rowsPerPage}`)
      .then((response) => {
        setData(response.data); // Assuming API returns paginated data under 'items'
        // setTotalRows(response.data.total); // Assuming API provides total row count
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleRowClick = (rowId) => {
    navigate(`/details/${rowId}`);
  };

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode,
          primary: globalTheme.palette.secondary,
          background: {
            default: globalTheme.palette.mode === 'light' ? '#f7f7f7' : '#000',
          },
        },
        typography: {
          button: {
            textTransform: 'none',
            fontSize: '1.2rem',
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: '1.1rem',
              },
            },
          },
        },
      }),
    [globalTheme],
  );

  // Define columns with styled headers
  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
      },
      {
        accessorKey: 'timestamp',
        header: 'Time',
        Cell: ({ cell }) => new Date(cell.getValue()).toLocaleString(),
      },
      {
        accessorKey: 'data_name',
        header: 'Agent',
      },
      {
        accessorKey: 'actions',
        header: '',
        Cell: ({ row }) => (
          <IconButton color="primary" onClick={() => handleRowClick(row.original.id)}>
            <KeyboardArrowRight />
          </IconButton>
        ),
      },
    ],
    [],
  );
  // return (
  //   <ThemeProvider theme={createTheme()}>
  //     <MaterialReactTable
  //       columns={columns}
  //       data={data}
  //       manualPagination
  //       rowCount={totalRows}
  //       enableColumnOrdering
  //       enableColumnPinning
  //       state={{
  //         pagination: { pageIndex: page, pageSize: rowsPerPage },
  //       }}
  //       onPaginationChange={({ pageIndex = 0, pageSize = 5 }) => {
  //         setPage(pageIndex);
  //         setRowsPerPage(pageSize);
  //       }}
  //       pageCount={Math.ceil(totalRows / rowsPerPage)}
  //     />
  //   </ThemeProvider>
  // );
  return (
    <ThemeProvider theme={tableTheme}>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableRowSelection
        enableColumnOrdering
        enableColumnPinning
      />
    </ThemeProvider>
  );
};
export default DataTable;
